import React, { useEffect, useMemo } from "react"
import { Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
import PropTypes from "prop-types"
import ColumnsDef from "../../../../table/column"
import noImage from "../../../../../../resources/images/noimage.png"
import dayjs from "dayjs"
import "dayjs/locale/ja"
import "./databable.css"
dayjs.locale("ja")

const useStyles = makeStyles({
  root: {
    color: "inherit",
  },
  image: {
    width: "340px",
  },
})

const DialogDetailDetailContentDataTable = (props) => {
  const classes = useStyles()
  const columnDef = useMemo(() => ColumnsDef({}), [])

  return (
    <Box id="dialog_detail_datatable" className={classes.root}>
      {props.data.photos &&
        props.data.photos.split(",").map((v) => {
          return (
            <Box key={v}>
              <img
                className={classes.image}
                src={`https://images-tokyo.s3.ap-northeast-1.amazonaws.com/${v}`}
              />
            </Box>
          )
        })}

      <table>
        {columnDef.flatMap((def) => {
          let d = props.data[def.field]
          if (!d) {
            return []
          }
          if (def.field === "photos") {
            return
          }
          if (def.field === "pdf") {
            return
          }

          switch (def.field) {
            case "date":
              d = dayjs(d).format("YYYY年M月D日(ddd)")
          }

          let tdStyle = ""
          if (def.field === "reason") {
            tdStyle = "style='white-space: normal;'"
          }

          return (
            <tr key={def.field}>
              <th>{def.headerName}</th>
              <td style={def.field === 'reason' ? {whiteSpace: "normal"} : {whiteSpace: "nowrap"}}>{d}</td>
            </tr>
          )
        })}
      </table>
    </Box>
  )
}

DialogDetailDetailContentDataTable.propTypes = {
  data: PropTypes.any,
  sx: PropTypes.object,
}

export default DialogDetailDetailContentDataTable
