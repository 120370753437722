import React, { useContext, useMemo, useState } from "react"
import ColumnDef from "../../table/column"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import { RootDataContext } from "../../index"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  root: {},
  notice: {
    color: "#e11515",
    fontWeight: "bold !important",
    backgroundColor: "#ffffff96",
  },
})

const MapControlLabelSelector = (props) => {
  const classes = useStyles()
  const { state, setMapLabel } = useContext(RootDataContext)
  const [labelName, setLabelName] = useState("表示なし")
  const columns = useMemo(
    () =>
      ColumnDef({}).reduce((l, v) => {
        l[v.field] = v
        return l
      }, {}),
    []
  )
  const onChange = (e) => {
    let value = e.target.value
    if (!columns[value]) {
      setMapLabel(null)
      setLabelName("表示なし")
      return
    }
    setMapLabel(value)
    setLabelName(columns[value].headerName)
  }

  return (
    <Box className={classes.root + " " + props.className}>
      {state.mapZoom < 19 && state.mapLabel && (
        <Typography
          textAlign="right"
          fontSize="small"
          className={classes.notice}
        >
          ※ラベルはズーム19から表示されます
        </Typography>
      )}
      <FormControl
        style={{ width: "100%", height: "100%", backgroundColor: "white" }}
      >
        <InputLabel id="map-label-selector-label">表示ラベル</InputLabel>
        <Select
          labelId="map-label-selector"
          id="map-label-selector"
          label="表示ラベル"
          value={state.mapLabel}
          onChange={onChange}
        >
          <MenuItem key={""} value={""}>
            表示なし
          </MenuItem>
          {ColumnDef({}).map((f) => (
            <MenuItem key={f.field} value={f.field}>
              {f.headerName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

MapControlLabelSelector.propTypes = {
  className: PropTypes.string,
}

export default MapControlLabelSelector
