import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import {
  Autocomplete,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Popper,
  TextField,
  Typography,
} from "@mui/material"
import { useState } from "react"
import axios from "axios"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  root: {
    width: "480px",
  },
  textField: {
    width: "100%",
    height: "100%",
  },
  label: {
    display: "flex",
    flexDirection: "column",
  },
  labelTitle: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  labelSubTitle: {
    fontSize: "6px",
  },
  pop: {
    zIndex: 10000,
    width: "480px",
    backgroundColor: "white",
  },
})

const MapControlPlaceSearch = (props) => {
  const classes = useStyles()
  const [options, setOptions] = useState(null)
  const textFieldRef = useRef(null)
  const inputRef = useRef(null)

  const onChange = (e) => {
    if (!e.nativeEvent.isComposing || e.key === "Enter") {
      let params = {
        query: e.target.value,
        c: "ja-jp",
        key: process.env.REACT_APP_BING_API_KEY,
      }

      // 検索
      let url =
        "https://dev.virtualearth.net/REST/v1/Locations?" +
        Object.keys(params)
          .map((k) => `${k}=${params[k]}`)
          .join("&")
      console.log("[BingSearch]", "request", url)
      axios.get(url).then((res) => {
        console.log("[BingSearch]", "response", res.data)

        if (!res.data.resourceSets) {
          console.log("[Response resourceSets]", "empty")
          setOptions(null)
          return
        }
        let sets = res.data.resourceSets[0]
        console.log("[Response resourceSets]", sets)

        let val =
          sets.resources.flatMap((v) => {
            if (v.address.adminDistrict !== "東京都") {
              return []
            }
            let addresses = []
            if (v.address?.locality) {
              addresses.push(v.address.locality)
            }
            if (v.address?.addressLine) {
              addresses.push(v.address.addressLine)
            }
            let text =
              v.name ?? v.address?.formattedAddress ?? v.address?.locality

            let address = addresses.length > 0 ? addresses.join("") : null
            if (address === text) {
              address = null
            }
            return [
              {
                text: text ?? address,
                place_name: address,
                center: v.point.coordinates,
              },
            ]
          }) ?? []
        console.log("[Response]", val)
        setOptions(val)
      })
    }
  }

  useEffect(() => {
    console.log("[Change options]", options)
  }, [options])

  const onSelect = (e, value) => {
    props.onPlaceSelected(value)
  }

  const onListItemClicked = (value) => {
    setOptions(null)
    props.onPlaceSelected(value)
  }

  return (
    <>
      <Box className={classes.root + " " + props.className}>
        <TextField
          className={classes.textField}
          label="住所・名称などで検索"
          autoComplete="off"
          ref={textFieldRef}
          inputRef={inputRef}
          onKeyDown={onChange}
        />
        <Popper
          className={classes.pop}
          id="search_result"
          open={!!options}
          anchorEl={textFieldRef.current}
          placement="bottom-start"
        >
          <List>
            {options &&
              options.map((v) => {
                return (
                  <ListItem key={v.text}>
                    <ListItemButton onClick={() => onListItemClicked(v)}>
                      <ListItemText primary={v.text} secondary={v.place_name} />
                    </ListItemButton>
                  </ListItem>
                )
              })}
          </List>
        </Popper>
      </Box>
    </>
  )
}

MapControlPlaceSearch.propTypes = {
  className: PropTypes.string,
  style: PropTypes.any,
  onPlaceSelected: PropTypes.func,
}

export default MapControlPlaceSearch
