import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { makeStyles } from "@mui/styles"
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import ChuteibokuTable from "./table/chuteboku"
import KobokuTable from "./table/koboku"
import { Close as CloseIcon } from "@mui/icons-material"
import PropTypes from "prop-types"
import GairojuManager from "../../../../manager/gairoju"
import {
  ExportSummaryExcel,
  exportSummaryExcel,
} from "../../../../manager/excel"
import { RootDataContext } from "../../index"
import SummaryTable from "./table"

const useStyles = makeStyles({
  root: {
    display: "flex",
    color: "inherit",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  dialog: {
    padding: "8px",
    minWidth: "500",
    width: "70%",
    minHeight: "500",
    height: "70%",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "1px 8px 12px 0 rgba(0,0,0, .5)",
  },
  title: {
    height: "20px",
  },
  content: {
    height: "calc(100% - 48px)",
    display: "flex",
    flexDirection: "column",
  },
})

export const ViewType = {
  TotalRankA: "total_rank_a",
  TotalRankB1: "total_rank_b1",
  TotalRankB2: "total_rank_b2",
  TotalRankC: "total_rank_c",
  NextEvalR4: "next_eval_r4",
  NextEvalR5: "next_eval_r5",
  NextEvalR6: "next_eval_r6",
}

export const ViewTypeName = {
  TotalRankA: "総合判定A",
  TotalRankB1: "総合判定B1",
  TotalRankB2: "総合判定B2",
  TotalRankC: "総合判定C",
  NextEvalR4: "次回診断令和4年度",
  NextEvalR5: "次回診断令和5年度",
  NextEvalR6: "次回診断令和6年度",
}

const RootDialogSummaryView = (props) => {
  const classes = useStyles()
  const { state } = useContext(RootDataContext)
  const [data, setData] = useState()
  const [tableData, setTableData] = useState([])
  const [viewType, setViewType] = useState(ViewType.TotalRankA)

  useEffect(() => {
    GairojuManager.summaryQuery(state.filterData)
      .then(res => {
        setData(res)
      })
      .catch(e => {
        console.log(e)
      })
  }, [state.filterData])

  useEffect(() => {
    console.log('Update', data, viewType)
    if (!data || !Object.keys(data).includes(viewType)) {
      setTableData([])
    } else {
      console.log('Show Data', data, viewType, data[viewType])
      setTableData(data[viewType])
    }
  }, [data, state.filterData, viewType])

  useEffect(() => {
    console.log(tableData)

  }, [tableData])

  const onExcelExport = async () => {
    await exportSummaryExcel(data, ViewType, ViewTypeName)
  }

  const onViewTypeChanged = (e) => {
    setViewType(e.target.value)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.dialog}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "30px",
            marginLeft: "8px",
          }}
        >
          <Typography variant="h6">ランク集計表</Typography>
          <Box flexGrow={1} />
          <Button variant="outlined" size="small" onClick={onExcelExport}>
            Excel出力
          </Button>
          <Box sx={{ m: 1 }} />
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider style={{ margin: "8px" }} />
        <Box className={classes.content}>
          <FormControl>
            <InputLabel id="summary-view-type-label">表示項目</InputLabel>
            <Select
              size="small"
              labelId="summary-view-type-label"
              id="summary-type-select"
              value={viewType}
              label="表示項目"
              onChange={onViewTypeChanged}
              sx={{
                width: "240px",
              }}
            >
              {Object.keys(ViewType).map((k) => (
                <MenuItem key={k} value={ViewType[k]}>
                  {ViewTypeName[k]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box
            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            <Typography style={{ marginLeft: "16px" }} fontSize="small">
            </Typography>
            <SummaryTable data={tableData} sx={{ flexGrow: 1 }} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

RootDialogSummaryView.propTypes = {
  onClose: PropTypes.func,
}

export default RootDialogSummaryView
