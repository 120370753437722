import React, { useState } from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import { AgGridReact } from "ag-grid-react"
import LOCALE_JA from "../../../../../resources/aggrid/locale.ja"
import ColumnDef from "./column"

const styles = {
  root: {},
}

const columnDef = ColumnDef()

const DialogSummaryTableView = (props) => {
  const [columnApi, setColumnApi] = useState()
  const [gridApi, setGridApi] = useState()

  const onGridReady = (params) => {}

  return (
    <Box sx={{ ...styles.root, ...props.sx }}>
      <AgGridReact
        onGridReady={onGridReady}
        columnDefs={columnDef}
        className={`ag-theme-balham`}
        localeText={LOCALE_JA}
        headerHeight={20}
        rowData={props.data}
        groupIncludeTotalFooter={true}
        defaultColDef={{
          resizable: true,
          sortable: true,
        }}
      />
    </Box>
  )
}

DialogSummaryTableView.propTypes = {
  data: PropTypes.any,
  sx: PropTypes.object,
}

export default DialogSummaryTableView
