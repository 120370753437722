import { filterModelToWhereStringArray } from "./filter"
import { First, One, Rows } from "./carto"

export default new (class {
  getById = (cartodbId) => {
    return new Promise((resolve, reject) => {
      First(
        "SELECT * FROM roadside_trees_diagnostic_view WHERE cartodb_id = " +
          cartodbId
      )
        .then((ret) => {
          resolve(ret)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  summaryQueryTotalRank = (totalRank, filter) => {
    return new Promise((resolve, reject) => {
      let wheres = ["TRUE", ...this.basicQuery(filter)]

      let sql = `
      SELECT
        road AS rosen_no,
        treename AS name,
        SUM(CASE WHEN judgement LIKE '${totalRank}%' AND height <= 29 THEN 1 ELSE 0 END) AS rank_a,
        SUM(CASE WHEN judgement LIKE '${totalRank}%' AND height BETWEEN 30 AND 59 THEN 1 ELSE 0 END) AS rank_b,
        SUM(CASE WHEN judgement LIKE '${totalRank}%' AND height BETWEEN 60 AND 89 THEN 1 ELSE 0 END) AS rank_c,
        SUM(CASE WHEN judgement LIKE '${totalRank}%' AND height BETWEEN 90 AND 119 THEN 1 ELSE 0 END) AS rank_d,
        SUM(CASE WHEN judgement LIKE '${totalRank}%' AND height BETWEEN 120 AND 149 THEN 1 ELSE 0 END) AS rank_e,
        SUM(CASE WHEN judgement LIKE '${totalRank}%' AND height BETWEEN 150 AND 179 THEN 1 ELSE 0 END) AS rank_f,
        SUM(CASE WHEN judgement LIKE '${totalRank}%' AND height BETWEEN 180 AND 209 THEN 1 ELSE 0 END) AS rank_g,
        SUM(CASE WHEN judgement LIKE '${totalRank}%' AND height BETWEEN 210 AND 239 THEN 1 ELSE 0 END) AS rank_h,
        SUM(CASE WHEN judgement LIKE '${totalRank}%' AND height BETWEEN 240 AND 269 THEN 1 ELSE 0 END) AS rank_i,
        SUM(CASE WHEN judgement LIKE '${totalRank}%' AND height BETWEEN 270 AND 299 THEN 1 ELSE 0 END) AS rank_j,
        SUM(CASE WHEN judgement LIKE '${totalRank}%' AND height >= 300 THEN 1 ELSE 0 END) AS rank_k,
        SUM(CASE WHEN judgement LIKE '${totalRank}%' THEN 1 ELSE 0 END) AS rosen_sum
      FROM roadside_trees_diagnostic_view
      WHERE ${wheres.join(" AND ")}
      GROUP BY 1, 2
      ORDER BY rosen_sum DESC
       `

      Rows(sql)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  summaryQueryNextEval = (year, filter) => {
    return new Promise((resolve, reject) => {
      let wheres = ["road IS NOT NULL", ...this.basicQuery(filter)]

      let sql = `
      SELECT
        road AS rosen_no,
        treename AS name,
        SUM(CASE WHEN next_schedule = ${year} AND height <= 29 THEN 1 ELSE 0 END) AS rank_a,
        SUM(CASE WHEN next_schedule = ${year} AND height BETWEEN 30 AND 59 THEN 1 ELSE 0 END) AS rank_b,
        SUM(CASE WHEN next_schedule = ${year} AND height BETWEEN 60 AND 89 THEN 1 ELSE 0 END) AS rank_c,
        SUM(CASE WHEN next_schedule = ${year} AND height BETWEEN 90 AND 119 THEN 1 ELSE 0 END) AS rank_d,
        SUM(CASE WHEN next_schedule = ${year} AND height BETWEEN 120 AND 149 THEN 1 ELSE 0 END) AS rank_e,
        SUM(CASE WHEN next_schedule = ${year} AND height BETWEEN 150 AND 179 THEN 1 ELSE 0 END) AS rank_f,
        SUM(CASE WHEN next_schedule = ${year} AND height BETWEEN 180 AND 209 THEN 1 ELSE 0 END) AS rank_g,
        SUM(CASE WHEN next_schedule = ${year} AND height BETWEEN 210 AND 239 THEN 1 ELSE 0 END) AS rank_h,
        SUM(CASE WHEN next_schedule = ${year} AND height BETWEEN 240 AND 269 THEN 1 ELSE 0 END) AS rank_i,
        SUM(CASE WHEN next_schedule = ${year} AND height BETWEEN 270 AND 299 THEN 1 ELSE 0 END) AS rank_j,
        SUM(CASE WHEN next_schedule = ${year} AND height >= 300 THEN 1 ELSE 0 END) AS rank_k,
        SUM(CASE WHEN next_schedule = ${year} THEN 1 ELSE 0 END) AS rosen_sum
      FROM roadside_trees_diagnostic_view
      WHERE ${wheres.join(" AND ")}
      GROUP BY 1, 2
      ORDER BY rosen_sum DESC
       `

      Rows(sql)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  summaryQuery = (filter) => {
    return new Promise((resolve, reject) => {
      let ret = {}
      Promise.all([
        new Promise((rs) => {
          this.summaryQueryTotalRank("A", filter).then((r) => {
            ret.total_rank_a = r
            rs()
          })
        }),
        new Promise((rs) => {
          this.summaryQueryTotalRank("B1", filter).then((r) => {
            ret.total_rank_b1 = r
            rs()
          })
        }),
        new Promise((rs) => {
          this.summaryQueryTotalRank("B2", filter).then((r) => {
            ret.total_rank_b2 = r
            rs()
          })
        }),
        new Promise((rs) => {
          this.summaryQueryTotalRank("C", filter).then((r) => {
            ret.total_rank_c = r
            rs()
          })
        }),
        new Promise((rs) => {
          this.summaryQueryNextEval(2022, filter).then((r) => {
            ret.next_eval_r4 = r
            rs()
          })
        }),
        new Promise((rs) => {
          this.summaryQueryNextEval(2023, filter).then((r) => {
            ret.next_eval_r5 = r
            rs()
          })
        }),
        new Promise((rs) => {
          this.summaryQueryNextEval(2024, filter).then((r) => {
            ret.next_eval_r6 = r
            rs()
          })
        }),
      ]).then(() => {
        resolve(ret)
      })
    })
  }

  chuteibokuSummaryQuery = (filter) => {
    return new Promise((resolve, reject) => {
      let wheres = this.basicQuery(filter)
      if (wheres.length === 0) {
        wheres = ["TRUE"]
      }

      let sql = `
      SELECT 
        route_number AS rosen_no,
             name,
        SUM(CASE WHEN chuteiboku_height_rank = 'A' THEN 1 ELSE 0 END) AS rank_a,
        SUM(CASE WHEN chuteiboku_height_rank = 'B' THEN 1 ELSE 0 END) AS rank_b,
        SUM(CASE WHEN chuteiboku_height_rank = 'C' THEN 1 ELSE 0 END) AS rank_c,
        SUM(CASE WHEN chuteiboku_height_rank = 'D' THEN 1 ELSE 0 END) AS rank_d,
        COUNT(*) AS rosen_sum
      FROM roadside_trees_diagnostic_view
      WHERE type IN ('低木', '中木') AND chuteiboku_height_rank IS NOT NULL AND ${wheres.join(
        " AND "
      )}
      GROUP BY route_number, name
      ORDER BY route_number::int
      `

      Rows(sql)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  kobokuSummaryQuery = (filter) => {
    return new Promise((resolve, reject) => {
      let wheres = this.basicQuery(filter)
      if (wheres.length === 0) {
        wheres = ["TRUE"]
      }

      let sql = `
      SELECT
        route_number AS rosen_no,
             name,
        SUM(CASE WHEN koboku_perimeter_rank = 'A' THEN 1 ELSE 0 END) AS rank_a,
        SUM(CASE WHEN koboku_perimeter_rank = 'B' THEN 1 ELSE 0 END) AS rank_b,
        SUM(CASE WHEN koboku_perimeter_rank = 'C' THEN 1 ELSE 0 END) AS rank_c,
        SUM(CASE WHEN koboku_perimeter_rank = 'D' THEN 1 ELSE 0 END) AS rank_d,
        SUM(CASE WHEN koboku_perimeter_rank = 'E' THEN 1 ELSE 0 END) AS rank_e,
        SUM(CASE WHEN koboku_perimeter_rank = 'F' THEN 1 ELSE 0 END) AS rank_f,
        SUM(CASE WHEN koboku_perimeter_rank = 'G' THEN 1 ELSE 0 END) AS rank_g,
        SUM(CASE WHEN koboku_perimeter_rank = 'H' THEN 1 ELSE 0 END) AS rank_h,
        SUM(CASE WHEN koboku_perimeter_rank = 'I' THEN 1 ELSE 0 END) AS rank_i,
        SUM(CASE WHEN koboku_perimeter_rank = 'J' THEN 1 ELSE 0 END) AS rank_j,
        SUM(CASE WHEN koboku_perimeter_rank = 'K' THEN 1 ELSE 0 END) AS rank_k,
        COUNT(*) AS rosen_sum
      FROM roadside_trees_diagnostic_view
      WHERE type = '高木' AND koboku_perimeter_rank IS NOT NULL
        AND ${wheres.join(" AND ")}
      GROUP BY route_number, name
      ORDER BY route_number::int
    `

      Rows(sql, {})
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  basicQuery = (filter) => {
    let wheres = filter
      ? Object.keys(filter).flatMap((name) => {
          let fName = name

          // 名前の変更
          if (name === "hedge_count") {
            fName =
              "CASE WHEN hedge_count = '多数' THEN 9999 ELSE hedge_count::int END"
          } else if (name === '"tree_count') {
            fName =
              "CASE WHEN tree_count = '多数' THEN 9999 ELSE tree_count::int END"
          } else if (name === "teiboku_name") {
            return this._filterModelTeibokuToWhereStringArray(filter[name])
          } else if (name === "is_dead") {
            let vals = []
            if (filter[name].values.includes("(空白)")) {
              vals.push(`is_dead IS NULL`)
            }
            if (filter[name].values.includes("枯れ")) {
              vals.push(`is_dead = 1`)
            }
            if (vals.length === 0) {
              return []
            } else {
              return [`(${vals.join(" OR ")})`]
            }
          } else if (name === "photos") {
            let vals = []
            if (filter[name].values.includes("写真あり")) {
              vals.push("photos IS NOT NULL")
            }
            if (filter[name].values.includes("写真なし")) {
              vals.push("photos IS NULL")
            }
            if (vals.length === 0) {
              return []
            } else {
              return [`(${vals.join(" OR ")})`]
            }
          } else if (name === "diagnostic_pdf") {
            let vals = []
            if (filter[name].values.includes("PDFあり")) {
              vals.push("diagnostic_pdf IS NOT NULL")
            }
            if (filter[name].values.includes("PDFなし")) {
              vals.push("diagnostic_pdf IS NULL")
            }
            if (vals.length === 0) {
              return []
            } else {
              return [`(${vals.join(" OR ")})`]
            }
          }

          return filterModelToWhereStringArray(fName, filter[name])
        })
      : null
    if ((wheres?.length ?? 0) === 0) {
      wheres = ["TRUE"]
    }

    return wheres
  }

  tableQuery = (params) => {
    let wheres = this.basicQuery(params.request.filterModel)

    let orderBy = params.request.sortModel.map((v) => {
      return `${v.colId} ${v.sort}`
    })
    if (orderBy.length === 0) {
      orderBy = ["id"]
    }

    let limit = params.request.endRow - (params.request.startRow ?? 0)
    let offset = params.request.startRow ?? 0

    Promise.all([
      this._getTableRows(wheres, orderBy, limit, offset),
      this._getTableCount(wheres),
    ])
      .then((values) => {
        params.success({
          rowData: values[0],
          rowCount: values[1],
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  selectLayerQuery = (filter, select) => {
    let wheres = this.basicQuery(filter)
    // 選択されたものだけを抽出するクエリを作成

    if (!select || Object.keys(select).length === 0) {
      wheres = ["FALSE"]
    } else {
      switch (select.field) {
        case "perimeter":
        case "width":
        case "height":
        case "hedge_length":
        case "pit_Number":
        case "tree_Number":
          wheres.push(`${select.field} = ${select.value}`)
          break
        case "date":
          wheres.push(`${select.field}::date = '${select.value}'::date`)
          break
        default:
          wheres.push(`${select.field} = '${select.value}'`)
      }
    }

    return `
      SELECT *, ST_Y(the_geom) AS latitude, ST_X(the_geom) AS longitude FROM roadside_trees_diagnostic_view
      WHERE ${wheres.join(" AND ")}
      `
  }

  exportQuery = (filter, sort) => {
    let wheres = this.basicQuery(filter)

    let orderBy =
      sort?.map((v) => {
        return `${v.colId} ${v.sort}`
      }) ?? []
    if (orderBy.length === 0) {
      orderBy = ["id"]
    }
    return `
    SELECT *
    FROM roadside_trees_diagnostic_view
    WHERE ${wheres.join(" AND ")}
    ORDER BY ${orderBy.join(", ")}
    `
  }

  kokuLayerQuery = () => {
    return `SELECT * FROM koku`
  }

  gyoseikaiLayerQuery = () => {
    return `sELECT * FROM tokyo_to_gyoseikai`
  }

  roadGridLayerQuery = () => {
    return `SELECT * FROM tokyo_roadgrid`
  }


  tableLayerQuery = (filter) => {
    let wheres = this.basicQuery(filter)

    return `
      SELECT * FROM roadside_trees_diagnostic_view
      WHERE ${wheres.join(" AND ")}
      `
  }

  labelLayerQuery = (filter) => {
    let wheres = this.basicQuery(filter)

    let sql = `
    SELECT
      the_geom_webmercator,
      cartodb_id,
      tree_id,
      daiagnostic_id,
      office,
      area,
      road,
      date,
      diagnostic_type,
      treename,
      height,
      perimeter,
      width,
      judgement,
      reason,
      note,
      next_daiagnostic,
      next_schedule,
      pdf,
      longitude,
      latitude,
      fill_color,
      selected_fill_color,
      route_number,
      route_name,
      carbon_storage_kg,
      carbon_absorption_kg,
      co2_absorption_kg,
      co_absorption_kg,
      no2_adsorption_kg,
      o3_adsorption_kg,
      pm2_5_adsorption_kg,
      so2_adsorption_kg,
      tree_canopy_interception_m3
    FROM roadside_trees_diagnostic_view
    WHERE ${wheres.join(" AND ")}
    `

    return sql
  }

  _filterModelTeibokuToWhereStringArray = (params) => {
    if (params.values.length === 0) {
      return ["FALSE"]
    }

    let v = "'{" + params.values.map((v) => `"${v}"`).join(",") + "}'"
    let val = " teiboku_array && " + v
    return [val]
  }

  _getTableRows = (wheres, order, limit, offset) => {
    return new Promise((resolve, reject) => {
      Rows(`
      SELECT * FROM roadside_trees_diagnostic_view
      WHERE ${wheres.join(" AND ")}
      ORDER BY ${order.join(",")}
      LIMIT ${limit} OFFSET ${offset}
      `)
        .then((ret) => {
          resolve(ret)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  _getTableCount = (wheres) => {
    return new Promise((resolve, reject) => {
      One(`
        SELECT COUNT(*) FROM roadside_trees_diagnostic_view
        WHERE ${wheres.join(" AND ")}
      `)
        .then((ret) => {
          resolve(ret)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
})()
