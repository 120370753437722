import React from "react"
import dayjs from "dayjs"
import {
  distinctFilterParams,
  distinctShikuchosonFilterParams,
  teibokuFilterParams,
} from "../../../manager/filter"

const RootTableColumnDef = (options) => [
  {
    field: "tree_id",
    headerName: "樹木ID",
    filter: "agTextColumnFilter",
    sort: "asc",
    pinned: "left",

    cellRendererFramework: (params) => {
      return (
        <span
          style={{
            color: "#38389d",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {params.value}
        </span>
      )
    },
  },
  {
    field: "daiagnostic_id",
    headerName: "カルテID",
    filter: "agTextColumnFilter",
  },
  {
    field: "office",
    headerName: "事務所名",
    filterParams: distinctFilterParams,
  },
  {
    field: "area",
    headerName: "工区",
    filterParams: distinctFilterParams,
  },
  {
    field: "road",
    headerName: "路線名(通称)",
    filter: "agTextColumnFilter",
  },
  {
    field: "date",
    headerName: "調査日付",
    filter: "agTextColumnFilter",
  },
  {
    field: "diagnostic_type",
    headerName: "調査種別",
    filterParams: distinctFilterParams,
  },
  {
    field: "treename",
    headerName: "樹種名",
    filterParams: distinctFilterParams,
  },
  {
    field: "height",
    headerName: "樹高",
    filter: "agNumberColumnFilter",
  },
  {
    field: "perimeter",
    headerName: "幹周",
    filter: "agNumberColumnFilter",
  },
  {
    field: "width",
    headerName: "枝張",
    filter: "agNumberColumnFilter",
  },
  {
    field: "judgement",
    headerName: "総合判定",
    filterParams: distinctFilterParams,
  },
  {
    field: "reason",
    headerName: "判定理由",
    filterParams: distinctFilterParams,
  },
  {
    field: "note",
    headerName: "備考",
    filter: "agTextColumnFilter",
  },
  {
    field: "next_diagnostic",
    headerName: "次回の診断種別",
    filterParams: distinctFilterParams,
  },
  {
    field: "next_schedule",
    headerName: "次回の診断予定",
    filterParams: distinctFilterParams,
  },
  {
    field: "pdf",
    headerName: "PDF",
    cellRenderer: "diagnosticPdfCellRenderer",
    filterParams: {
      values: (params) => {
        params.success(["PDFなし", "PDFあり"])
      },
    },
  },
  {
    field: "carbon_storage_kg",
    headerName: "炭素貯蔵量（kg）",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "carbon_absorption_kg",
    headerName: "炭素吸収量（kg/年）",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "co2_absorption_kg",
    headerName: "CO₂吸収量（kg/年）",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "co_absorption_kg",
    headerName: "CO吸着量（kg/年）",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "no2_adsorption_kg",
    headerName: "NO₂吸着量（kg/年）",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "o3_adsorption_kg",
    headerName: "O₃吸着量（kg/年）",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "pm2_5_adsorption_kg",
    headerName: "PM2.5吸着量（kg/年）",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "so2_adsorption_kg",
    headerName: "SO₂吸着量（kg/年）",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "tree_canopy_interception_m3",
    headerName: "樹冠遮断量（m3/年",
    filter: "agNumberColumnFilter",
    hide: true,
  },
]

export default RootTableColumnDef
